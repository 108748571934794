/*! StartNoStringValidationRegion Does not contain captions */
import bindingEvaluator from 'BindingEvaluator';
import calculatedPropertyCore from 'CalculatedProperty';
import { registerCalculatedProperties } from 'CalculatedPropertyRegistrar';
import captionService from 'CaptionService';
import classHelper from 'ClassHelper';
import constants from 'Constants';
import 'CustomOData4UriBuilder';
import dataService from 'DataService';
import { getDataType } from 'DataTypes';
import Dependency from 'Dependency';
import dependencyExtractor from 'DependencyExtractorVisitor';
import { using } from 'Disposable';
import { getHumanReadableName } from 'EntityCalculatedPropertyExtensions';
import {
	addNewToCollectionAsync,
	getFirstOrDefaultChildAsync,
	getOrCreateFirstChildAsync,
	getOrCreateSingleChildAsync,
	getSingleOrDefaultChildAsync,
} from 'EntityCollectionExtensions';
import { shallowCopyExistingAsync } from 'EntityCopyExtensions';
import { createEntityAsync, getOrCreateRelatedObjectAsync } from 'EntityCreationExtensions';
import { getPrimaryKey, ensureResourceName, deactivateEntity, activateEntity, isEntityActive } from 'EntityExtensions';
import { fetchEntitiesByKeyAsync, notifyAttachAndDetach, observeHasChanges, trackEntityManagerOperationAsync, waitForEntityManagerOperationsAsync } from 'EntityManagerExtensions';
import entityMappingService from 'EntityMappingService';
import { excludeReadOnlyProperty } from 'EntityMetadataExtensions';
import { BooleanPredicate, IsNullPredicate, TypeOfPredicate } from 'EntityPredicateExtensions';
import { getPropertyValueAsync, hasPropertyChanged } from 'EntityPropertyExtensions';
import { enableProposedValuesAsync, getDependencyGraph, suspendValidation } from 'EntityRuleExtensions';
import entitySetRightsProvider from 'EntitySetRightsProvider';
import errors from 'Errors';
import { normalizeExpandPath } from 'ExpandPathsProvider';
import guid from 'GuidGenerator';
import lookupRuleService from 'LookupRuleService';
import Notifications from 'Notifications';
import oData from 'OData';
import { getODataRoute, trimEndNormalSpace } from 'ODataUtils';
import PropertyVertex from 'PropertyVertex';
import proposedValueEngine from 'ProposedValueEngine';
import ResourceStreams from 'ResourceStreams';
import Rule from 'Rule';
import RuleExpressionCondition from 'RuleExpressionCondition';
import RuleService from 'RuleService';
import { isEmptyGuid, startsWith } from 'StringUtils';
import { synchronizeAsync } from 'Synchronizer';
import systemAuditPropertiesHelper from 'SystemAuditPropertiesHelper';
import { getRouteName, joinUri } from 'UriUtils';
import userSession from 'UserSession';
import validationEngine from 'ValidationEngine';
import ValidationRuleVertex from 'ValidationRuleVertex';
import Promise from 'bluebird';
import breeze from 'breeze-client';
import 'breeze-client/breeze.modelLibrary.ko';
import { OData4PredicateVisitor } from 'breeze-odata4';
import 'breeze.dataService.glow';
import 'breeze.dataService.glow.light';
import ko from 'knockout';
import { defaults, find, isObject, isString, isUndefined, pickBy, zip } from 'lodash-es';
import moment from 'moment';
import {
	clearMetadataStoreCache,
	getMetadataStoreForEntityTypeAsync,
	getMetadataStoreForRouteAsync,
	getMetadataStoreWithFetcher,
	getRouteNameForMetadataStore,
} from 'MetadataStoreExtensions';

function calculatedProperty(entity, rule) {
	const result = calculatedPropertyCore(entity, rule);
	entity.entityAspect._glowSubscriptions.push(result);
	return result;
}

(function configureBreeze() {
	breeze.config.initializeAdapterInstance('dataService', 'glow', true /*default*/);
	breeze.config.initializeAdapterInstance('dataService', 'glow.light', false /*default*/);
	breeze.config.initializeAdapterInstance('modelLibrary', 'ko', true);
	breeze.config.initializeAdapterInstance('uriBuilder', 'CustomOData4', true /*default*/);

	breeze.DataType.DateTime.defaultValue = moment.minValue.toDate();
	breeze.DataType.DateTimeOffset.defaultValue = moment.minValue.toDate();
	oData.jsonHandler.recognizeDates = false;
})();

(function overrideValidators() {
	const baseRequired = breeze.Validator.required;
	breeze.Validator.required = () => {
		const result = baseRequired();
		result.context.allowEmptyStrings = true;
		return result;
	};

	const baseMaxLength = breeze.Validator.maxLength;
	breeze.Validator.maxLength = () => {
		const result = baseMaxLength();
		result.valFn = () => {
			return true;
		};
		return result;
	};

	const baseValidate = breeze.Validator.prototype.validate;
	breeze.Validator.prototype.validate = function () {
		const result = baseValidate.apply(this, arguments);
		this.currentContext.entity = null;
		return result;
	};
})();

(() => {
	breeze.DataType.DateTimeOffset.fmtOData = function newfmtDateTimeOffset(val) {
		if (val === null) {
			return null;
		}

		return val.hasOffset ? val.toISOString() : `${moment(val).toFilterValueString()}Z`;
	};

	breeze.DataType.Duration.fmtOData = function newfmtDuration(val) {
		if (val == null) {
			return null;
		}

		if (!breeze.core.isDuration(val)) {
			throw new Error(`${val} is not a valid ISO 8601 duration`);
		}

		return `duration'${val}'`;
	};
})();

(function overrideDataTypeFromValue() {
	const baseFromValue = breeze.DataType.fromValue;
	breeze.DataType.fromValue = (val) => {
		if (val instanceof Date) {
			return breeze.DataType.DateTimeOffset;
		}
		return baseFromValue(val);
	};
})();

(function extendEntityAspect() {
	Object.defineProperties(breeze.EntityAspect.prototype, {
		hasAnyPropertyChanged: getPropertyDescriptor(
			/** @this EntityAspect */
			function () {
				if (!this._hasAnyPropertyChanged) {
					this._hasAnyPropertyChanged = ko.observable(false);
				}
				return this._hasAnyPropertyChanged;
			}
		),

		humanReadableName: getPropertyDescriptor(
			/** @this EntityAspect */
			function () {
				return getHumanReadableName(this.entity);
			}
		),

		isReadOnly: getPropertyDescriptor(
			/** @this EntityAspect */
			function () {
				if (!this._isReadOnly && !excludeReadOnlyProperty(this.entity.entityType)) {
					this._isReadOnly = getIsReadOnlyProperty(this.entity);
				}
				return this._isReadOnly;
			}
		),

		notifications: getPropertyDescriptor(
			/** @this EntityAspect */
			function () {
				if (!this._notifications) {
					this._notifications = new Notifications();
				}
				return this._notifications;
			}
		),

		proposedValues: getPropertyDescriptor(
			/** @this EntityAspect */
			function () {
				if (!this._proposedValues) {
					this._proposedValues = proposedValueEngine.createStorage(this.entity);
					this._glowSubscriptions.push(this._proposedValues);
				}
				return this._proposedValues;
			}
		),
	});

	function getIsReadOnlyProperty(entity) {
		const dependencies = [];
		const isReadOnly = RuleService.get(entity.entityType.interfaceName).isReadOnly();

		let conditionExpression;
		if (isReadOnly && isReadOnly.Condition) {
			conditionExpression = new RuleExpressionCondition(isReadOnly.Condition);
			const conditionDependencies = dependencyExtractor.visitDependency(
				isReadOnly.Condition,
				{ extractPathsOnly: true }
			);
			conditionDependencies.forEach((path) => {
				dependencies.push(path);
			});
		}

		if (userSession.isLoggedOn()) {
			const rightsInfo = entitySetRightsProvider.get(entity.entityType);
			const canWriteConditionDependencies = dependencyExtractor.visitDependency(
				rightsInfo.canWriteCondition,
				{ extractPathsOnly: true }
			);
			canWriteConditionDependencies.forEach((path) => {
				dependencies.push(path);
			});
		}

		return calculatedProperty(
			entity,
			new Rule({
				id: guid.newGuid(),
				components: [
					{
						dependencies,
						func() {
							let result = isReadOnly === true;
							if (!result && userSession.isLoggedOn()) {
								const rightsInfo = entitySetRightsProvider.get(entity.entityType);
								result = !rightsInfo.canWrite(entity);
							}
							if (!result && conditionExpression) {
								const conditionValue = conditionExpression.evaluate(entity).value;
								return conditionValue !== false;
							}
							return result;
						},
					},
				],
			})
		);
	}

	breeze.EntityAspect.prototype.getDescriptionFromLookupListAsync = function (
		propertyPath,
		value
	) {
		const entity = this.entity;
		const gettingValue =
			arguments.length > 1
				? Promise.resolve(value)
				: Dependency.loadValueAsync(entity, propertyPath);
		return gettingValue.then((value) => {
			if (!value) {
				return '';
			}

			const propertyInfo = bindingEvaluator.getEntityBindingInfo(
				entity.entityType,
				propertyPath
			);
			return lookupRuleService.getDescriptionLookupAsync(
				propertyInfo.entityName,
				propertyInfo.propertyName,
				entity,
				value
			);
		});
	};

	breeze.EntityAspect.prototype.getState = function (propertyName) {
		const property = this.entity[propertyName];
		return property ? property.getState() : constants.States.NotAvailable;
	};

	breeze.EntityAspect.prototype.markAsLoaded = function (propertyName) {
		const property = this.entity[propertyName];
		if (property && property.markAsLoaded) {
			property.markAsLoaded();
		}
	};

	breeze.EntityAspect.prototype.getPrimaryKey = function () {
		return getPrimaryKey(this.entity);
	};

	breeze.EntityAspect.prototype.getUri = function () {
		let result =
			this.entityManager.dataService.serviceName +
			this.entity.entityType.ensureResourceName();
		const keyProperty = this.entity.entityType.keyProperties[0];
		const keyValue = keyProperty.dataType.fmtOData(this.entity[keyProperty.name]());
		result = result + '(' + keyValue + ')';
		return result;
	};

	/** @deprecated Use EntityPropertyExtensions.getPropertyValueAsync instead  */
	breeze.EntityAspect.prototype.getValueAsync = function (propertyName) {
		return getPropertyValueAsync(this.entity, propertyName);
	};

	breeze.EntityAspect.prototype.hasPropertyChanged = function (propertyName) {
		return hasPropertyChanged(this.entity, propertyName);
	};

	breeze.EntityAspect.prototype.getOriginalValue = function (propertyName) {
		return this.hasPropertyChanged(propertyName)
			? this.originalValues[propertyName]
			: this.entity[propertyName]();
	};

	/** @deprecated Use EntityExtensions.isEntityActive instead  */
	breeze.EntityAspect.prototype.isActive = function () {
		return isEntityActive(this.entity);
	};

	const originalRejectChanges = breeze.EntityAspect.prototype.rejectChanges;
	breeze.EntityAspect.prototype.rejectChanges = function (keepNotifications) {
		if (!keepNotifications) {
			this.notifications.removeAll();
		}
		originalRejectChanges.call(this);
	};

	breeze.EntityAspect.prototype.deleteAsync = function () {
		return deleteCoreAsync(this, new Set());
	};

	async function deleteCoreAsync(self, visitedEntities) {
		self.notifications.removeAll();
		disposeSubscriptions(self.entity);

		await removeRelationsAsync(self.entity, visitedEntities);

		if (self.entityState.isDetached()) {
			return;
		}

		const itemsAffected = []; // get items now in case some are detached/deleted
		for (const navProperty of self.entity.entityType.navigationProperties) {
			if (navProperty.inverse && !navProperty.inverse.isScalar) {
				const key = self.entity[navProperty.foreignKeyNames[0]]();
				if (!isEmptyGuid(key)) {
					const parent = self.entity.entityAspect.entityManager.getEntityByKey(
						navProperty.entityType,
						key
					);
					if (parent && !parent.entityAspect.entityState.isDeleted()) {
						itemsAffected.push({
							entity: parent,
							propertyName: navProperty.inverse.name,
						});
					}
				}
			}
		}

		if (!self.entityState.isAdded()) {
			self.setDeleted();
		} else {
			self.setDetached();
		}

		return await tryNotifyAndValidateChangesAsync(itemsAffected);
	}

	async function removeRelationsAsync(entity, visitedEntities) {
		if (visitedEntities.has(entity)) {
			return;
		}
		visitedEntities.add(entity);

		const rules = RuleService.get(entity.entityType.interfaceName);

		for (const navigationProperty of entity.entityType.navigationProperties) {
			if (navigationProperty.isScalar) {
				continue;
			}

			const propertyName = navigationProperty.name;
			const relatedEntities = entity[propertyName]().slice();

			if (navigationProperty.cascadeDelete) {
				await deleteRelationsAsync(relatedEntities, visitedEntities, true);
			} else {
				const removalMode = rules.removalMode(propertyName);
				if (
					removalMode &&
					(removalMode.mode === 'Detach' || removalMode.mode === 'DeleteAndDetach')
				) {
					await detachRelationsAsync(
						relatedEntities,
						navigationProperty.inverse.foreignKeyNames[0],
						visitedEntities
					);
				} else {
					await deleteRelationsAsync(relatedEntities, visitedEntities, false);
				}
			}
		}
	}

	async function deleteRelationsAsync(relatedEntities, visitedEntities, cascadeDelete) {
		for (const entity of relatedEntities) {
			if (entity.entityAspect.entityState.isAdded()) {
				await deleteCoreAsync(entity.entityAspect, visitedEntities);
			} else if (cascadeDelete) {
				// The entity will be deleted on the server-side but we still want to clean up
				// local changes made to relations of the entity.
				await removeRelationsAsync(entity, visitedEntities);
			}
		}
	}

	async function detachRelationsAsync(relatedEntities, foreignKeyName, visitedEntities) {
		for (const entity of relatedEntities) {
			if (entity.entityAspect.entityState.isAdded()) {
				await deleteCoreAsync(entity.entityAspect, visitedEntities);
			} else {
				entity[foreignKeyName](null);
			}
		}
	}

	/** @deprecated Use EntityExtensions.deactivateEntity instead  */
	breeze.EntityAspect.prototype.deactivate = function () {
		deactivateEntity(this.entity);
	};

	/** @deprecated Use EntityExtensions.activateEntity instead  */
	breeze.EntityAspect.prototype.activate = function () {
		activateEntity(this.entity);
	};

	/** @deprecated Use EntityCollectionExtensions.addNewToCollectionAsync instead  */
	breeze.EntityAspect.prototype.addNewRelatedAsync = function (
		collectionOrPropertyName,
		fallbackEntityType
	) {
		const property = Array.isArray(collectionOrPropertyName)
			? collectionOrPropertyName.navigationProperty
			: collectionOrPropertyName;
		return addNewToCollectionAsync(this.entity, property, {
			fallbackEntityType,
		});
	};

	breeze.EntityAspect.prototype.expandAsync = function (expandPaths) {
		const entityState = this.entityState;
		if (entityState.isDeleted() || entityState.isDetached()) {
			throw new Error('You cannot expand a deleted entity.');
		}

		if (entityState.isAdded() || expandPaths.length === 0) {
			return Promise.resolve();
		}

		const entityKey = this.getKey();
		const query = breeze.EntityQuery.fromEntityKey(entityKey).expand(expandPaths);
		return this.entityManager.executeQuery(query).return(undefined);
	};

	breeze.EntityAspect.prototype.setValueAsync = function (propertyName, value) {
		return Promise.try(() => {
			const entity = this.entity;
			const property = entity[propertyName];

			if (!property) {
				throw new Error(
					`Cannot set property ${entity.entityType.interfaceName}.${propertyName} because it does not exist.`
				);
			}

			if (this.entityState.isDetached()) {
				throw new Error(
					`Cannot set property ${entity.entityType.interfaceName}.${propertyName} because the entity is detached.`
				);
			}

			if (value && value.entityAspect && value.entityAspect.entityState.isDetached()) {
				throw new Error(
					`Cannot set property ${entity.entityType.interfaceName}.${propertyName} to a detached entity of type ${value.entityType.interfaceName}.`
				);
			}

			if (property.writeAsync) {
				return property.writeAsync(value);
			} else {
				const oldValue = property();
				if (value === oldValue) {
					return Promise.resolve();
				}
				runWithoutPropertyChanged(this, () => {
					property(value);
				});

				let propertyMetadata = entity.entityType.getProperty(propertyName);
				if (propertyMetadata.isNavigationProperty) {
					propertyMetadata = entity.entityType.getProperty(
						propertyMetadata.foreignKeyNames[0]
					);
					value = value && value.entityAspect.getPrimaryKey();
				}

				return onPropertyChangedAsync({
					entity,
					newValue: value,
					oldValue,
					property: propertyMetadata,
					propertyName: propertyMetadata.name,
				});
			}
		});
	};

	function disposeSubscriptions(entity) {
		entity.entityAspect._glowSubscriptions.forEach((item) => {
			item.dispose();
		});

		entity.entityAspect._glowSubscriptions = [];
	}

	breeze.EntityAspect.prototype.getCachedValue = function (key) {
		if (this.cacheStorage && key in this.cacheStorage) {
			return this.cacheStorage[key];
		}
		return null;
	};

	breeze.EntityAspect.prototype.setCachedValue = function (key, value) {
		if (!this.cacheStorage) {
			this.cacheStorage = {};
		}
		this.cacheStorage[key] = value;
	};

	breeze.EntityAspect.prototype.synchronizeAsync = function (work) {
		return synchronizeAsync(this, work);
	};

	breeze.EntityAspect.prototype.getCodeAsync = function () {
		return getMetadataPropertyAsync(this.entity, 'codeProperty');
	};

	breeze.EntityAspect.prototype.getDescriptionAsync = function () {
		return getMetadataPropertyAsync(this.entity, 'descriptionProperty');
	};

	breeze.EntityAspect.prototype.getTypeDescriptionAsync = function () {
		const entity = this.entity;
		return getMetadataPropertyAsync(entity, 'typeDescriptionProperty').then((metadataValue) => {
			return (
				metadataValue ||
				captionService.getCaptionFromInterfaceName(entity.entityType.interfaceName).caption
			);
		});
	};

	function runWithoutPropertyChanged(entityAspect, func) {
		entityAspect._propertyChangesSuspended = true;

		func();

		entityAspect._propertyChangesSuspended = false;
	}

	function getMetadataPropertyAsync(entity, propName) {
		const rules = RuleService.get(entity.entityType.interfaceName);
		const propertyPath = rules[propName]();
		if (propertyPath) {
			return Dependency.loadValueAsync(entity, propertyPath).then((value) => {
				return value || '';
			});
		}
		return Promise.resolve('');
	}

	breeze.EntityAspect.prototype.getCode = function () {
		return getMetadataProperty(this.entity, 'codeProperty');
	};

	breeze.EntityAspect.prototype.getDescription = function () {
		return getMetadataProperty(this.entity, 'descriptionProperty');
	};

	function getMetadataProperty(entity, propName) {
		const rules = RuleService.get(entity.entityType.interfaceName);
		const propertyPath = rules[propName]();
		if (propertyPath) {
			return Dependency.getValue(entity, propertyPath) || '';
		}
		return '';
	}

	breeze.EntityAspect.prototype.gatherPropertyChangedArgs = function (propertyChangedArgs) {
		if (!this._propertyChangedArgsList) {
			this._propertyChangedArgsList = [];
		}

		this._propertyChangedArgsList.push(propertyChangedArgs);
	};

	breeze.EntityAspect.prototype.consumePropertyChangedArgsList = function () {
		const result = this._propertyChangedArgsList;

		this._propertyChangedArgsList = undefined;

		return result ?? [];
	};
})();

(function extendEntityManager() {
	const BaseEntityManager = breeze.EntityManager;

	function EntityManager(config) {
		config = configWithDefaultMetadataStore(config);
		addDefaultValidationOptions(config);
		BaseEntityManager.call(this, config);

		this.publicStorage = {};
		this._relationMerge = { current: undefined, queryMap: new Map() };
		this.dependencyGraph = getDependencyGraph(this);
		this.observeHasChanges = ko.observable(false);
		this.resourceStreams = new ResourceStreams(this);

		this.hasChangesChanged.subscribe(onHasChangesChanged);
		this.entityChanged.subscribe((e) => {
			onEntityChanged(this, e);
		});

		observeHasChanges(this);
	}

	classHelper.inherit(EntityManager, BaseEntityManager);

	EntityManager.forEntityType = (entityType) => {
		return breeze.EntityManager.withMetadataStore(entityType.metadataStore);
	};

	EntityManager.forEntityTypeAsync = (entityInterfaceName, okIfNotFound) => {
		return breeze.MetadataStore.forEntityTypeAsync(entityInterfaceName, okIfNotFound).then(
			(metadataStore) => {
				return metadataStore && breeze.EntityManager.withMetadataStore(metadataStore);
			}
		);
	};

	EntityManager.forRouteAsync = (routeName) => {
		return breeze.MetadataStore.forRouteAsync(routeName).then((metadataStore) => {
			return breeze.EntityManager.withMetadataStore(metadataStore);
		});
	};

	EntityManager.withMetadataStore = (metadataStore) => {
		const routeName = metadataStore.getRouteName();
		if (!routeName) {
			throw new Error('Metadata store does not have a route.');
		}

		return new breeze.EntityManager({
			serviceName: getODataRoute(routeName),
			metadataStore,
		});
	};

	EntityManager.prototype.addPromise = function (promise) {
		return trackEntityManagerOperationAsync(this, promise);
	};

	EntityManager.prototype.waitOperationsAsync = function () {
		return waitForEntityManagerOperationsAsync(this);
	};

	EntityManager.prototype.createEntity = function (entityType, initialValues, entityState) {
		if (entityState === breeze.EntityState.Detached) {
			throw new Error(
				'Cannot create entity in a detached state because we rely on having an entity manager to initialize entities.'
			);
		}

		if (typeof entityType === 'string') {
			entityType = this.metadataStore.getEntityType(entityType);
		}

		if (entityType.isAbstract) {
			throw new Error('Cannot create abstract entity type: ' + entityType.interfaceName);
		}

		if (initialValues) {
			validateInitialValues(entityType, initialValues);
		}

		return BaseEntityManager.prototype.createEntity.apply(this, arguments);
	};

	function validateInitialValues(entityType, initialValues) {
		Object.entries(initialValues).forEach(([propertyName, value]) => {
			const property = entityType.getProperty(propertyName);
			if (!property) {
				throw new Error(
					`Cannot set property ${entityType.interfaceName}.${propertyName} because it does not exist.`
				);
			}

			if (property.isNavigationProperty && value && !value.entityAspect) {
				throw new Error(
					`Cannot set property ${entityType.interfaceName}.${propertyName} because the provided value is not a Breeze entity instance.`
				);
			}

			runBreezePropertyValidation(property, value);
		});
	}

	/** @deprecated Use EntityCreationExtensions.createEntityAsync instead  */
	EntityManager.prototype.createEntityWithKeyAsync = function (entityType, options) {
		return createEntityAsync(this, entityType, options);
	};

	EntityManager.prototype.attachEntity = function (entity, entityState) {
		try {
			return BaseEntityManager.prototype.attachEntity.call(this, entity, entityState);
		} catch (error) {
			if (error.message === 'This entity already belongs to another EntityManager') {
				throw new errors.BreezeAttachEntityError(error.message, error);
			}
			throw error;
		}
	};

	EntityManager.prototype.createQuery = function (entityTypeOrName) {
		return this.metadataStore.createQuery(entityTypeOrName).using(this);
	};

	EntityManager.prototype.executeQuery = function (query, callback, errorCallback) {
		return Promise.try(() => {
			return preProcessQuery(this, cloneQuery(query));
		}).then((query) => {
			return BaseEntityManager.prototype.executeQuery
				.call(this, query, callback, errorCallback)
				.catch((error) => {
					throw new errors.BreezeQueryError(error);
				})
				.finally(() => {
					return commitMergeFromQueryAsync(this, query);
				});
		});
	};

	function commitMergeFromQueryAsync(entityManager, query) {
		const currentMerge = entityManager._relationMerge.queryMap.get(query);
		if (!currentMerge) {
			return;
		}

		entityManager._relationMerge.queryMap.delete(query);
		return commitRelationMergeAsync(currentMerge);
	}

	function commitRelationMergeAsync(currentMerge) {
		currentMerge.entities.forEach(initEntityAfterLinkingRelations);

		const relationsToNotify = new Map();
		currentMerge.relations.forEach((relation) => {
			addRelation(relation.entity, relation.property);
			if (relation.property.inverse) {
				addRelation(relation.value, relation.property.inverse);
			}
		});

		if (relationsToNotify.size) {
			const promises = [];
			for (const [entity, properties] of relationsToNotify.entries()) {
				properties.forEach((p) => {
					promises.push(tryNotifyDependentsAsync(entity, p.name, true));
				});
			}
			return Promise.all(promises);
		}

		function addRelation(entity, property) {
			if (currentMerge.entities.has(entity)) {
				return;
			}

			const context = entity[property.name]._context;
			if (context.pendingLoader || context.pendingLoaderForLoadFirst) {
				return;
			}

			let entityRelations = relationsToNotify.get(entity);
			if (!entityRelations) {
				entityRelations = new Set();
				relationsToNotify.set(entity, entityRelations);
			}
			entityRelations.add(property);
		}
	}

	const originalExportEntities = breeze.EntityManager.prototype.exportEntities;
	breeze.EntityManager.prototype.exportEntities = function (entities, config) {
		if (!entities) {
			return originalExportEntities.call(this, entities, config);
		}

		const includeMetadata =
			typeof config === 'boolean' ? config : !config || config.includeMetadata !== false;
		const result = exportEntitiesWithoutExpands(this, entities, includeMetadata);
		return config && config.asString === false ? result : JSON.stringify(result);
	};

	function exportEntitiesWithoutExpands(self, entities, includeMetadata) {
		const result = originalExportEntities.call(self, entities, {
			includeMetadata,
			asString: false,
		});
		Object.keys(result.entityGroupMap).forEach((key) => {
			result.entityGroupMap[key].entities.forEach((entity) => {
				const metadata = entity.entityAspect.extraMetadata;
				if (metadata && metadata.expands) {
					delete metadata.expands;
				}
			});
		});

		return result;
	}

	EntityManager.prototype.fetchEntityByKey = function () {
		const params = arguments;
		return BaseEntityManager.prototype.fetchEntityByKey.apply(this, params);
	};

	async function importEntitiesWithRelationMergeAsync(entityManager, exportedDataSets, config) {
		const relationMerge = entityManager._relationMerge;
		if (relationMerge.current) {
			throw new Error('Cannot run while merge already in progress');
		}
		const currentMerge = { entities: new Set(), relations: [] };
		relationMerge.current = currentMerge;
		let results;
		try {
			results = exportedDataSets.map((exportedData) =>
				entityManager.importEntities(exportedData, config)
			);
		} finally {
			relationMerge.current = undefined;
			// function must be synchronous up until this point
			// to avoid suspension while relationMerge.current is set
			await commitRelationMergeAsync(currentMerge);
		}
		return results;
	}

	EntityManager.prototype.importEntitiesAsync = async function (exportedData, config) {
		const [result] = await importEntitiesWithRelationMergeAsync(this, [exportedData], config);
		return result;
	};

	EntityManager.prototype.importEntitiesFromOtherAsync = async function (
		entities,
		{ exportConfig, importConfig } = {}
	) {
		if (entities.length === 0) {
			return [];
		}
		exportConfig = defaults(exportConfig || {}, { asString: false, includeMetadata: false });

		const entitiesPerManager = new Map();
		const entityOriginalIndicesPerManager = new Map();
		const allImportedEntities = Array(entities.length);
		entities.forEach((entity, i) => {
			const manager = entity.entityAspect.entityManager;
			if (!entitiesPerManager.has(manager)) {
				entitiesPerManager.set(manager, []);
				entityOriginalIndicesPerManager.set(manager, []);
			}
			entitiesPerManager.get(manager).push(entity);
			entityOriginalIndicesPerManager.get(manager).push(i);
		});
		const managers = Array.from(entitiesPerManager.keys());
		const exportedDataSets = Array.from(
			entitiesPerManager.entries(),
			([manager, sourceEntities]) => manager.exportEntities(sourceEntities, exportConfig)
		);
		const importResults = await importEntitiesWithRelationMergeAsync(
			this,
			exportedDataSets,
			importConfig
		);
		zip(managers, importResults).forEach(([manager, importResult]) => {
			const originalIndices = entityOriginalIndicesPerManager.get(manager);
			const importedEntities = importResult.entities;
			zip(originalIndices, importedEntities).forEach(([i, importedEntity]) => {
				allImportedEntities[i] = importedEntity;
			});
		});
		return allImportedEntities;
	};

	/** @deprecated use EntityManagerExtensions.fetchEntitiesByKeyAsync instead */
	EntityManager.prototype.fetchEntitiesByKeyAsync = function (entityType, keys, expandPaths) {
		return fetchEntitiesByKeyAsync(this, entityType, keys, expandPaths);
	};

	EntityManager.prototype.executeCountQueryAsync = function (query) {
		const uri = joinUri(this.metadataStore.getRouteName(), query.toCountUri(this));

		return dataService.getAsync(uri).then((count) => Number(count));
	};

	// Initialize entities for Glow after entityAspect.entityManager is set, immediately before entities are linked to their navigation properties.
	// We can't use the Attach(OnQuery) event because it is fired after the linking process, which is too late and can cause issues with KO subscriptions on navigation properties.
	EntityManager.prototype._linkRelatedEntities = function (entity) {
		initEntity(entity);
		return BaseEntityManager.prototype._linkRelatedEntities.call(this, entity);
	};

	function configWithDefaultMetadataStore(config) {
		let routeName;
		if (isString(config)) {
			routeName = getRouteName(config);
			config = {
				serviceName: config,
				metadataStore: breeze.MetadataStore._get(routeName),
			};
		} else if (isObject(config) && config !== null && !config.metadataStore) {
			routeName = getRouteName(config.serviceName || config.dataService.serviceName);
			config = {...config};
			config.metadataStore = breeze.MetadataStore._get(routeName);
		}

		return config;
	}

	function addDefaultValidationOptions(config) {
		if (!config.validationOptions) {
			config.validationOptions = new breeze.ValidationOptions({
				validateOnPropertyChange: false,
			});
		}
	}

	function onEntityChanged(entityManager, e) {
		switch (e.entityAction) {
			case breeze.EntityAction.Attach:
			case breeze.EntityAction.AttachOnQuery:
				notifyAttachAndDetach(entityManager);
				break;

			case breeze.EntityAction.AcceptChanges:
			case breeze.EntityAction.EntityStateChange:
			case breeze.EntityAction.MergeOnSave:
			case breeze.EntityAction.RejectChanges:
				if (e.entity.entityAspect.hasAnyPropertyChanged) {
					const entityState = e.entity.entityAspect.entityState;
					if (entityState.isUnchanged() || entityState.isDeleted()) {
						e.entity.entityAspect.hasAnyPropertyChanged(false);
					}
				}
				break;

			case breeze.EntityAction.Detach:
				notifyAttachAndDetach(entityManager);
				break;
		}
	}

	function onHasChangesChanged(e) {
		e.entityManager.observeHasChanges(e.hasChanges);
	}

	function cloneQuery(query) {
		return query.take(query.takeCount);
	}

	function preProcessQuery(entityManager, query) {
		if (!query || !query.ignoreInvalidOrderByEnabled) {
			return query;
		}

		return removeInvalidOrderBy(entityManager, query);
	}

	function removeInvalidOrderBy(entityManager, query) {
		if (!query.orderByClause) {
			return query;
		}

		const metadataStore = entityManager.metadataStore;
		const entityTypeName = metadataStore.getEntityTypeNameForResourceName(query.resourceName);
		const entityType = metadataStore.getEntityType(entityTypeName);
		const clauses = query.orderByClause.items;
		const validClauses = clauses.filter((clause) => {
			return entityType.getProperty(clause.propertyPath);
		});

		if (clauses.length === validClauses.length) {
			return query;
		}
		if (validClauses.length === 0) {
			return query.orderBy(null);
		}

		const orderBy = validClauses.map((clause) => {
			let result = clause.propertyPath;
			if (clause.isDesc) {
				result += ' desc';
			}
			return result;
		});

		return query.orderBy(null).orderBy(orderBy);
	}

	breeze.EntityManager = EntityManager;
})();

(function extendEntityQuery() {
	const originalExpand = breeze.EntityQuery.prototype.expand;
	breeze.EntityQuery.prototype.expand = function (propertyPaths) {
		if (isString(propertyPaths)) {
			if (!propertyPaths) {
				return this;
			}

			propertyPaths = normalizeExpandPath(propertyPaths).split(',');
		} else if (Array.isArray(propertyPaths) && propertyPaths.some(hasSlash)) {
			propertyPaths = propertyPaths.map(normalizeExpandPath);
		}

		validateExpandDepth(propertyPaths);

		return originalExpand.call(this, propertyPaths);

		function hasSlash(path) {
			return path.indexOf('/') > -1;
		}

		function validateExpandDepth(paths) {
			if (!paths) {
				return;
			}

			paths.forEach(throwIfDepthOverLimit);

			function throwIfDepthOverLimit(path) {
				if (path.split('.').length > constants.ExpandDepthLimit) {
					throw new errors.ExpandPathTooDeepError(
						`Expand depth exceeded the max limit of ${constants.ExpandDepthLimit}. Current expand path is '${path}'.`
					);
				}
			}
		}
	};

	breeze.EntityQuery.prototype.extendClone = function (copy) {
		if ('ignoreInvalidOrderByEnabled' in this) {
			copy.ignoreInvalidOrderByEnabled = this.ignoreInvalidOrderByEnabled;
		}
	};

	breeze.EntityQuery.prototype.ignoreInvalidOrderBy = function (enabled) {
		const copy = this.take(this.takeCount);
		copy.ignoreInvalidOrderByEnabled = enabled === true;
		return copy;
	};

	const originalWithParameters = breeze.EntityQuery.prototype.withParameters;
	breeze.EntityQuery.prototype.withParameters = function (parameters) {
		const parametersWithValue = pickBy(parameters, (value) => value != null);
		return originalWithParameters.call(this, parametersWithValue);
	};

	breeze.EntityQuery.prototype.toCountUri = function (entityManager) {
		const copy = this.take(null).skip(null).inlineCount(false);
		copy.resourceName += '/$count';

		return copy._toUri(entityManager);
	};
})();

(function extendEntityType() {
	const baseSetCtor = breeze.EntityType.prototype._setCtor;

	breeze.EntityType.prototype._setCtor = function () {
		baseSetCtor.apply(this, arguments);
		this._ctor.prototype._$interceptorBase = this._ctor.prototype._$interceptor;
		this._ctor.prototype._$interceptor = propertyInterceptor;
	};

	/** @deprecated use EntityExtensions.ensureResourceName instead */
	breeze.EntityType.prototype.ensureResourceName = function () {
		return ensureResourceName(this);
	};

	breeze.EntityType.prototype.getDateTimeType = function (propertyName) {
		const property = this.getDataProperty(propertyName);

		if (!property) {
			return null;
		}

		const isValidProperty =
			property.dataType === breeze.DataType.DateTimeOffset ||
			property.dataType === breeze.DataType.DateTime;

		return isValidProperty ? property.dateTimeType : null;
	};

	breeze.EntityType.prototype.getEmptyValue = function (propertyName, okIfNotFound) {
		const property = this.getProperty(propertyName);
		if (property) {
			if (!property.isDataProperty) {
				throw new Error(`Cannot get empty value for non-data property "${propertyName}".`);
			}

			return property.emptyValue;
		}

		const rules = RuleService.get(this.interfaceName);
		const rule = rules.propertyRule(propertyName, okIfNotFound);
		if (rule && rule.returnType) {
			const type = getDataType(rule.returnType);
			if (type) {
				return type.emptyValue;
			}
		}

		if (!okIfNotFound) {
			throw new Error(
				`Cannot get empty value for calculated property "${propertyName}" with return type "${rule.returnType}".`
			);
		}
	};

	breeze.EntityType.prototype.getMeasureProperty = function (magnitudePropertyName) {
		return find(this.measureProperties, { magnitudeProperty: magnitudePropertyName });
	};

	breeze.EntityType.prototype.getStreamProperty = function (streamPropertyName) {
		return find(this.streamProperties, { name: streamPropertyName });
	};

	breeze.EntityType.prototype.isQueryable = function () {
		return !!this.defaultResourceName;
	};

	breeze.EntityType.prototype.registerCalculatedProperties = function () {
		if (!this._calculatedProperties) {
			const rules = RuleService.get(this.interfaceName);
			const properties = {};
			rules.propertyRules().forEach((rule) => {
				if (!this.getProperty(rule.property)) {
					properties[rule.property] = getPropertyDescriptor(
						getCalculatedPropertyGetter(rule)
					);
				}
			});

			registerCalculatedProperties(this);
			Object.defineProperties(this._ctor.prototype, properties);
			this._calculatedProperties = Object.keys(properties);
		}

		return this._calculatedProperties;
	};

	breeze.EntityType.prototype.resetCalculatedProperties = function () {
		const properties = this._calculatedProperties;
		if (properties) {
			properties.forEach((p) => delete this._ctor.prototype[p]);
			this._calculatedProperties = undefined;
		}
	};

	function getCalculatedPropertyGetter(rule) {
		/** @this Entity */
		return function () {
			const { entityAspect } = this;
			if (!entityAspect._glowCalcProps) {
				entityAspect._glowCalcProps = new Map();
			}

			let result = entityAspect._glowCalcProps.get(rule.property);
			if (!result) {
				result = calculatedProperty(this, rule);
				entityAspect._glowCalcProps.set(rule.property, result);
			}

			return result;
		};
	}

	function keepNavigationPropertyInSync(entity, property, oldValue, newValue) {
		const navigationProperty = property.relatedNavigationProperty;

		if (oldValue !== null) {
			removeOldNavigationPropertyMapping(entity, oldValue, navigationProperty);
		}

		const navigationPropertyName = navigationProperty.name;
		const navigationPropertyObservable = entity[navigationPropertyName];
		const navigationPropertyValue = navigationPropertyObservable();
		entity[navigationPropertyName]._context.pendingLoader = null;
		entity[navigationPropertyName]._context.pendingLoaderForLoadFirst = null;

		if (isEmptyGuid(newValue)) {
			navigationPropertyObservable._setIsDeferred(false);
			if (navigationPropertyValue !== null) {
				return navigationPropertyObservable;
			}
		} else if (navigationPropertyValue !== null) {
			if (trimEndNormalSpace(navigationPropertyValue.entityAspect.getPrimaryKey()) === trimEndNormalSpace(newValue)) {
				navigationPropertyObservable._setIsDeferred(false);
			} else {
				navigationPropertyObservable._setIsDeferred(true);
				return navigationPropertyObservable;
			}
		} else {
			navigationPropertyObservable._setIsDeferred(true);
			return navigationPropertyObservable;
		}
	}

	/** @this Entity */
	function propertyInterceptor(property, newValue, accessorFn) {
		const entity = this;

		if (!entity.entityAspect.entityState.isDetached()) {
			const oldValue = entity[property.name]();
			const dataType = property.dataType;
			if (newValue != null) {
				if (property.isDataProperty && dataType.parse) {
					newValue = dataType.parse(newValue, typeof newValue);
				}
			} else if (
				dataType === breeze.DataType.String &&
				!property.isNullable &&
				property.relatedNavigationProperty
			) {
				newValue = '';
			}

			const isChanged = !(
				oldValue === newValue ||
				(dataType?.normalize &&
					newValue &&
					oldValue &&
					dataType.normalize(newValue) === dataType.normalize(oldValue))
			);
			if (isChanged) {
				gatherPropertyChangedArgsIfNeeded({ entity, property, oldValue, newValue });

				runBreezePropertyValidation(property, newValue);
				accessorFn = interceptPropertyValueChange(
					entity,
					property,
					oldValue,
					newValue,
					accessorFn
				);
			}
		}

		try {
			entity.entityType._ctor.prototype._$interceptorBase.call(
				entity,
				property,
				newValue,
				accessorFn
			);
		} catch (error) {
			if (
				error.message ===
				'An Entity cannot be attached to an entity in another EntityManager. One of the two entities must be detached first.'
			) {
				throw new errors.BreezeAttachEntityError(error.message, error);
			}
			throw error;
		}
	}

	function interceptPropertyValueChange(entity, property, oldValue, newValue, accessorFn) {
		if (property.isDataProperty) {
			if (property.relatedNavigationProperty) {
				return function () {
					let observableToNotify;

					if (arguments.length > 0) {
						observableToNotify = keepNavigationPropertyInSync(
							entity,
							property,
							oldValue,
							newValue
						);
					}

					const result = accessorFn(...arguments);

					if (observableToNotify) {
						// If a navigation property is set and there is a subscription on the property that changes the FK property, it will cause this line to trigger.
						// However, notifySubscribers will do nothing internally as it detects a recursion and returns immediately. So subscribers will not be notified of the 2nd update.
						// This is theoretically an issue but we should not be writing subscription code like that.

						if (observableToNotify() === null) {
							observableToNotify.notifySubscribers(null);
						} else {
							observableToNotify(null);
						}
					}

					return result;
				};
			}
		} else if (property.isScalar && newValue) {
			const currentMerge = entity.entityAspect.entityManager._relationMerge.current;
			if (currentMerge) {
				currentMerge.relations.push({ entity, property, value: newValue });
			}
		}

		return accessorFn;
	}

	function gatherPropertyChangedArgsIfNeeded({ entity, property, oldValue, newValue }) {
		const { entityAspect } = entity;
		const { entityManager } = entityAspect;
		if (!entityManager.isLoading && !entityManager.isRejectingChanges) {
			return;
		}

		entityAspect.gatherPropertyChangedArgs({
			entity,
			property,
			propertyName: property.name,
			oldValue,
			newValue,
			suspendProposedValueAndValidation: true,
		});
	}

	function removeOldNavigationPropertyMapping(entity, oldKey, navigationProperty) {
		oldKey = new breeze.EntityKey(navigationProperty.entityType, oldKey);

		const unattachedChildrenMap = entity.entityAspect.entityManager._unattachedChildrenMap;
		const mapForKey = unattachedChildrenMap.map[oldKey.toString()];

		if (mapForKey) {
			for (let i = 0; i < mapForKey.length; i++) {
				const mapForProperty = mapForKey[i];

				if (mapForProperty.navigationProperty === navigationProperty) {
					const index = mapForProperty.children.indexOf(entity);

					if (index !== -1) {
						mapForProperty.children.splice(index, 1);

						if (mapForProperty.children.length === 0) {
							unattachedChildrenMap.removeChildrenByString(
								oldKey.toString(),
								navigationProperty
							);
						}
					}

					break;
				}
			}
		}
	}
})();

(function extendEvent() {
	const basePublish = breeze.core.Event.prototype.publish;

	breeze.core.Event.prototype.publish = function (data, publishAsync, errorCallback) {
		basePublish.call(this, data, publishAsync, errorCallback || throwError);
	};

	function throwError(error) {
		throw error;
	}
})();

const initEntity = (() => {
	function extendDataProperties(entity) {
		for (let i = 0; i < entity.entityType.dataProperties.length; i++) {
			const property = entity.entityType.dataProperties[i];
			const observable = entity[property.name];
			observable.getState = getDataPropertyState.bind(null, entity, property);
		}
	}

	function getDataPropertyState(entity, property) {
		const extraMetadata = entity.entityAspect.extraMetadata;
		const unknownProperties = extraMetadata && extraMetadata.unknownProperties;
		const isUnknown = unknownProperties && unknownProperties.includes(property.name);
		return isUnknown ? constants.States.NotAvailable : constants.States.Available;
	}

	function extendNavigationProperties(entity) {
		for (let i = 0; i < entity.entityType.navigationProperties.length; i++) {
			const property = entity.entityType.navigationProperties[i];
			const observable = entity[property.name];
			Object.assign(observable, navPropertyFn);
			observable._context = {
				entity,
				isDeferred: true,
				pendingLoader: null,
				pendingLoaderForLoadFirst: null,
				property,
			};
		}
	}

	const navPropertyFn = {
		markAsLoaded() {
			this._setIsDeferred(false);
		},
		/*
		For parent/child related data grid we rely on binding "parent/detail" to get first parent item to show child details,
		but binding "parent/detail" will trigger loading the entire collection of parent, this is a temporary fix to avoid the eager loading (WI00480004).
		TODO: Remove these code after completion of WI00483716 and WI00483718.
		*/
		temporaryHack_loadFirstAsync() {
			return Promise.try(() => {
				const observable = this;
				const context = this._context;
				const property = context.property;

				if (property.isScalar) {
					throw new Error('Scalar properties are not supported.');
				}

				if (observable.getState() !== constants.States.NotLoaded || observable().length) {
					return observable()[0];
				}

				let pendingLoader = context.pendingLoader;
				if (pendingLoader) {
					return pendingLoader.then(() => observable()[0]);
				}

				pendingLoader = context.pendingLoaderForLoadFirst;
				if (pendingLoader) {
					return pendingLoader;
				}

				const entity = context.entity;
				const query = breeze.EntityQuery.fromEntityNavigation(entity, property.name)
					.take(1)
					.inlineCount();

				pendingLoader = entity.entityAspect.entityManager
					.executeQuery(query)
					.then((data) => {
						if (observable.getState() !== constants.States.NotLoaded) {
							return observable()[0];
						}

						let inlineCount = data.inlineCount;
						if (inlineCount == null) {
							inlineCount = 0;
						}

						if (data.results && data.results.length === inlineCount) {
							observable._setIsDeferred(false);
						}

						const result = observable();
						observable.notifySubscribers(result);
						return tryNotifyDependentsAsync(entity, property.name, true).return(
							result[0]
						);
					})
					.catch((error) => {
						if (observable.getState() !== constants.States.NotLoaded) {
							return observable()[0];
						}

						const innerError = errors.findError(error, errors.DataServiceRequestError);
						const forbiddenStatus = 403;
						if (innerError && innerError.status === forbiddenStatus) {
							observable._setIsDeferred(false);
							context.innerStatus = constants.States.NotAvailable;
							observable.valueHasMutated();
							return tryNotifyDependentsAsync(entity, property.name, true).return(
								undefined
							);
						}

						throw error;
					})
					.finally(() => {
						context.pendingLoaderForLoadFirst = null;
					});

				context.pendingLoaderForLoadFirst = pendingLoader;
				return pendingLoader;
			});
		},
		loadAsync(forceLoad) {
			const observable = this;
			const context = this._context;
			const entity = context.entity;
			const property = context.property;

			if (forceLoad && property.isScalar) {
				return Promise.reject(new Error('Cannot use forceLoad with scalar properties.'));
			}

			if (!forceLoad && observable.getState() !== constants.States.NotLoaded) {
				return Promise.resolve(observable());
			}

			let pendingLoader = context.pendingLoader;
			if (pendingLoader) {
				return pendingLoader;
			}

			const promise = loadNavigationProperty(entity, property);
			if (!promise) {
				observable._setIsDeferred(false);
				return Promise.resolve(null);
			}

			pendingLoader = promise
				.then(() => {
					if (context.pendingLoader === pendingLoader) {
						observable._setIsDeferred(false);
						const result = observable();
						observable.notifySubscribers(result);
						return tryNotifyDependentsAsync(entity, property.name, true).return(result);
					} else {
						// This doesn't work yet. The idea is to switch over to a new loader if the FK has changed.
						// However, Breeze has a bug where if the first query finishes executing after your FK has changed
						// then the entity returned by the first query will still set up the navigation property association and revert the FK back to the previous value.
						return observable.loadAsync();
					}
				})
				.catch((error) => {
					if (context.pendingLoader === pendingLoader) {
						const innerError = errors.findError(error, errors.DataServiceRequestError);
						const forbiddenStatus = 403;
						if (innerError && innerError.status === forbiddenStatus) {
							observable._setIsDeferred(false);
							context.innerStatus = constants.States.NotAvailable;
							const result = property.isScalar ? null : [];
							observable.notifySubscribers(result);
							return tryNotifyDependentsAsync(entity, property.name, true).return(
								result
							);
						}
						throw error;
					}

					return observable.loadAsync();
				})
				.finally(() => {
					if (context.pendingLoader === pendingLoader) {
						context.pendingLoader = null;
					}
				});

			context.pendingLoader = pendingLoader;
			return pendingLoader;
		},
		getState() {
			const context = this._context;
			if (context.innerStatus) {
				return context.innerStatus;
			}

			const entity = context.entity;
			const property = context.property;
			const fkProperty = entity[property.foreignKeyNames[0]];

			if (fkProperty && fkProperty.getState() === constants.States.NotAvailable) {
				return constants.States.NotAvailable;
			} else {
				if (context.isDeferred) {
					this._setIsDeferred(!isLoaded(entity, property));
				}

				return context.isDeferred ? constants.States.NotLoaded : constants.States.Available;
			}
		},
		_setIsDeferred(value) {
			const context = this._context;
			let metadata = context.entity.entityAspect.extraMetadata;

			if (value) {
				if (metadata && metadata.expands) {
					const expands = metadata.expands;
					const expandIndex = expands.indexOf(this._context.property.name);
					if (expandIndex > -1) {
						expands.splice(expandIndex, 1);
					}
				}
			} else if (!context.property.isScalar) {
				if (!metadata) {
					context.entity.entityAspect.extraMetadata = metadata = {};
				}
				if (!metadata.expands) {
					metadata.expands = [];
				}
				if (!metadata.expands.includes(context.property.name)) {
					metadata.expands.push(context.property.name);
				}
			}

			context.isDeferred = value;
		},
	};

	function initEntityCore(entity) {
		const entityAspect = entity.entityAspect;
		if (entityAspect._glowInit) {
			return;
		}

		entity.entityType.registerCalculatedProperties();
		entityAspect._glowInit = true;
		entityAspect._glowSubscriptions = [];

		extendDataProperties(entity);
		extendNavigationProperties(entity);

		entityAspect.propertyChanged.subscribe(onPropertyChangedAsync);

		const currentMerge = entityAspect.entityManager._relationMerge.current;
		if (currentMerge) {
			currentMerge.entities.add(entity);
		} else {
			initEntityAfterLinkingRelations(entity);
		}
	}

	function isLoaded(entity, property) {
		const expands = entity.entityAspect.extraMetadata
			? entity.entityAspect.extraMetadata.expands
			: null;
		let result = expands && expands.indexOf(property.name) !== -1;

		if (!result) {
			if (property.isScalar) {
				const value = entity[property.foreignKeyNames[0]]();
				result = isEmptyGuid(value);
				if (!result) {
					const candidate = entity[property.name].peek();
					result = !!candidate && candidate.entityAspect.getPrimaryKey() === value;
				}
			} else {
				result = entity.entityAspect.entityState.isAdded();
				if (!result) {
					const inverse = property.inverse;
					if (
						inverse &&
						inverse.parentType.getDataProperty(inverse.foreignKeyNames[0]).isUnique
					) {
						result = entity[property.name].peek().length > 0;
					}
				}
			}
		}

		return result;
	}

	function loadNavigationProperty(entity, property) {
		if (property.isScalar) {
			const key = entity[property.foreignKeyNames[0]]();

			if (isEmptyGuid(key)) {
				return null;
			} else {
				const checkLocalCacheFirst = true;
				return entity.entityAspect.entityManager
					.fetchEntityByKey(property.entityType.shortName, key, checkLocalCacheFirst)
					.then((data) => {
						return data.entity || null;
					});
			}
		} else {
			return entity.entityAspect.loadNavigationProperty(property.name).get('results');
		}
	}

	return initEntityCore;
})();

const initEntityAfterLinkingRelations = (() => {
	return function initEntityAfterLinkingRelations(entity) {
		if (!entity.entityAspect.entityManager.disableGlowValidationOnPropertyChange) {
			const rules = RuleService.get(entity.entityType.interfaceName);
			addRuleValidation(entity, rules);
		}
	};

	function addRuleValidation(entity, rules) {
		Object.values(rules.validationRules()).forEach((validationRules) => {
			validationRules.forEach((rule) => {
				if (rule.hasPathDependenciesOtherThanRuleProperty()) {
					const vertex = new ValidationRuleVertex(entity, rule);
					vertex.wireDependencies();
				}
			});
		});
	}
})();

(function extendMappingContext() {
	const originalVisitAndMerge = breeze.MappingContext.prototype.visitAndMerge;
	breeze.MappingContext.prototype.visitAndMerge = function () {
		const relationMerge = this.entityManager._relationMerge;
		const isTopLevelQuery = !relationMerge.current && !!this.query;
		if (isTopLevelQuery) {
			const currentMerge = { entities: new Set(), relations: [] };
			relationMerge.queryMap.set(this.query, currentMerge);
			relationMerge.current = currentMerge;
		}

		try {
			return originalVisitAndMerge.apply(this, arguments);
		} finally {
			if (isTopLevelQuery) {
				relationMerge.current = undefined;
			}
		}
	};
})();

(function extendMetadataStore() {
	const BaseMetadataStore = breeze.MetadataStore;

	const dataPropertyExtensionParsers = {
		DateTimeOffset(property, csdl, rules) {
			const dateTimeType = rules.dateTimeType(property.name);
			property.dateTimeType = dateTimeType || constants.DateTimeTypes.DateTimeOffset;
		},
		Decimal(property, csdl, rules) {
			const numericSize = rules.numericSize(property.name);
			property.precision = numericSize ? numericSize.precision : null;
			property.scale = numericSize ? numericSize.scale : null;
			property.dynamicScale = numericSize ? numericSize.dynamicScale : null;
		},
		Guid: parseUniqueAttribute,
		String: parseUniqueAttribute,
	};

	function parseUniqueAttribute(property, csdl) {
		const propertyCsdl = csdl.property && csdl.property.find((x) => x.name === property.name);
		if (
			propertyCsdl &&
			propertyCsdl.annotation &&
			propertyCsdl.annotation.some((x) => x.term === 'Glow.IsUnique' && x.bool === 'true')
		) {
			property.isUnique = true;
		}
	}

	function MetadataStore() {
		BaseMetadataStore.apply(this, arguments);
		this._functionImportMap = {};
		this._routeName = null;
		this._metadataFetcher = null;
	}

	classHelper.inherit(MetadataStore, BaseMetadataStore);

	MetadataStore.clear = () => {
		clearMetadataStoreCache();
	};

	MetadataStore.forEntityTypeAsync = (entityInterfaceName, okIfNotFound) => {
		return getMetadataStoreForEntityTypeAsync(entityInterfaceName, okIfNotFound);
	};

	MetadataStore.forRouteAsync = (routeName) => {
		return getMetadataStoreForRouteAsync(routeName);
	};

	MetadataStore._get = (routeName) => {
		const [metadataStore] = getMetadataStoreWithFetcher(routeName);
		return metadataStore;
	};

	MetadataStore.prototype.addEntityType = function (structuralType) {
		BaseMetadataStore.prototype.addEntityType.call(this, structuralType);

		const entityType = this.getEntityType(structuralType.shortName);
		if (!structuralType.isComplexType) {
			entityType.interfaceName = entityMappingService.getInterfaceName(
				structuralType.shortName
			);
		}

		for (let i = 0; i < entityType.dataProperties.length; i++) {
			const property = entityType.dataProperties[i];
			if (isUndefined(property.emptyValue)) {
				property.emptyValue =
					property.isNullable || !property.dataType
						? null
						: property.dataType.defaultValue;
			}
		}

		if (entityType.baseEntityType) {
			entityType.baseEntityType.navigationProperties.forEach((property) => {
				if (property.cascadeDelete) {
					entityType.getNavigationProperty(property.name).cascadeDelete = true;
				}
			});
		}
	};

	MetadataStore.prototype.canAddEntityType = (shortName) => {
		return entityMappingService.hasInterfaceName(shortName);
	};

	MetadataStore.prototype.createQuery = function (entityTypeOrName) {
		let entityType;
		if (typeof entityTypeOrName === 'string') {
			entityType = this.getEntityType(entityTypeOrName);
		} else {
			entityType = entityTypeOrName;
			if (entityType.metadataStore !== this) {
				throw new Error(
					'Cannnot create query for entity type with a different metadata store.'
				);
			}
		}

		return new breeze.EntityQuery(entityType.ensureResourceName());
	};

	MetadataStore.prototype.fetchMetadata = async function (dataService) {
		const expectedRouteName = getRouteNameForMetadataStore(this);
		try {
			const serviceName = dataService.serviceName || dataService;
			const routeName = getRouteName(serviceName);

			if (routeName !== expectedRouteName) {
				throw new Error('Attempted to fetch metadata for different route: ' + routeName);
			} else if (!this.hasMetadataFor(getODataRoute(routeName))) {
				await BaseMetadataStore.prototype.fetchMetadata.call(this, serviceName);
			}
		} catch(error) {
			throw new errors.BreezeMetadataError(expectedRouteName, error);
		}
	};

	MetadataStore.prototype.getFunctionImport = function (name, okIfNotFound) {
		const result = this._functionImportMap[name];

		if (!result && !okIfNotFound) {
			throw new Error(
				`Unable to locate a 'FunctionImport' by the name: '${name}'. Be sure to execute a query or call fetchMetadata first.`
			);
		}

		return result;
	};

	MetadataStore.prototype.getFunctionImports = function () {
		const result = [];

		for (const key in this._functionImportMap) {
			result.push(this._functionImportMap[key]);
		}

		return result;
	};

	MetadataStore.prototype.importMetadata = function (exportedMetadata) {
		BaseMetadataStore.prototype.importMetadata.call(this, exportedMetadata);
		mapHierarchyTypeEntitySets(this);
		return this;
	};

	MetadataStore.prototype.parseAdditionalSchemaMetadata = function (schemas) {
		const functionMap = new Map();

		for (const schema of schemas) {
			const functions = schema.function;

			if (!functions) {
				continue;
			}

			for (const func of functions) {
				functionMap.set(func.name, func);
			}
		}

		schemas.forEach((schema) => {
			if (!schema.entityContainer) {
				return;
			}

			const { functionImport = [] } = schema.entityContainer;

			functionImport.forEach((funcImport) => {
				const func = functionMap.get(funcImport.name);

				if (!func) {
					throw new Error(`Unable to locate function with name ${funcImport.name}`);
				}

				const mapped = mapFunctionImport(this, { ...funcImport, ...func });
				this._functionImportMap[funcImport.name] = mapped;

				if (mapped.collectionEntityType) {
					this._resourceEntityTypeMap[mapped.name] = mapped.collectionEntityType.name;
				}
			});
		});
	};

	MetadataStore.prototype.parseExtensions = (entityType, csdl) => {
		const rules = RuleService.get(entityType.interfaceName);
		parseConfiguredMetadata(entityType, rules);
		parseTableCodeExtension(entityType, csdl);
		parseInfoTypeExtension(entityType, csdl);
		parseDataPropertyExtensions(entityType, csdl, rules);
		parseMeasurePropertyExtensions(entityType, csdl);
		parseStreamPropertyExtensions(entityType, csdl);
	};

	MetadataStore.prototype.completeParseCsdlNavProperty = (navigationProperty, csdlProperty) => {
		navigationProperty.cascadeDelete = csdlProperty.onDelete?.action === 'Cascade';
	};

	MetadataStore.prototype._getEntityType = function (typeName, okIfNotFound) {
		if (typeName && typeName[0] === 'I' && typeName.indexOf(':#') === -1) {
			const resolvedTypeName = entityMappingService.getTypeName(typeName, okIfNotFound);
			if (resolvedTypeName) {
				typeName = resolvedTypeName;
			}
		}
		return BaseMetadataStore.prototype._getEntityType.call(this, typeName, okIfNotFound);
	};

	MetadataStore.prototype.getRouteName = function () {
		return getRouteNameForMetadataStore(this);
	};

	function mapFunctionImport(metadataStore, item) {
		const result = { name: item.name, collectionEntityType: null, parameters: [] };
		const type = item.returnType.type;

		if (item.entitySet && startsWith(type, 'Collection(')) {
			const index = type.lastIndexOf('.');
			const returnType = type.substring(index + 1, type.length - 1);
			result.collectionEntityType = metadataStore.getEntityType(returnType);
		}

		if (item.parameter) {
			for (let i = 0; i < item.parameter.length; i++) {
				const parameter = item.parameter[i];
				result.parameters.push({
					name: parameter.name,
					type: breeze.DataType.fromEdmDataType(parameter.type),
				});
			}
		}

		return result;
	}

	function mapHierarchyTypeEntitySets(metadataStore) {
		const types = metadataStore.getEntityTypes();
		for (let i = 0; i < types.length; i++) {
			const type = types[i];
			if (
				!type.defaultResourceName &&
				type.baseEntityType &&
				type.baseEntityType.defaultResourceName
			) {
				const entitySetName = `${type.baseEntityType.defaultResourceName}/${type.namespace}.${type.shortName}`;
				metadataStore.setEntityTypeForResourceName(entitySetName, type.name);
			}
		}
	}

	function parseConfiguredMetadata(entityType, rules) {
		if (rules.nonExpandable()) {
			entityType.isNotExpandable = true;
		}
	}

	function parseDataPropertyExtensions(entityType, csdl, rules) {
		for (let i = 0; i < entityType.dataProperties.length; i++) {
			const property = entityType.dataProperties[i];
			const parser = property.dataType
				? dataPropertyExtensionParsers[property.dataType.name]
				: null;

			if (parser) {
				parser(property, csdl, rules);
			}
		}
	}

	function parseMeasurePropertyExtensions(entityType, csdl) {
		let measureProperties;
		if (entityType.baseEntityType && entityType.baseEntityType.measureProperties) {
			measureProperties = entityType.baseEntityType.measureProperties;
		}

		const annotation = find(csdl.annotation, { term: 'Glow.MeasureProperties' });

		if (!annotation) {
			return;
		}

		const propertyValues = annotation.collection.record.map((item) => item.propertyValue);
		measureProperties = measureProperties ? measureProperties.slice(0) : [];

		for (const propertyValue of propertyValues) {
			const mapped = {};
			for (const { property, propertyPath, enumMember } of propertyValue) {
				switch (property) {
					case 'MagnitudeProperty':
						mapped.magnitudeProperty = propertyPath;
						break;
					case 'UnitProperty':
						mapped.unitProperty = propertyPath;
						break;
					case 'UnitType':
						mapped.unitType = enumMember.text.split('/')[1];
						break;
				}
			}
			measureProperties.push(mapped);
		}

		if (measureProperties) {
			entityType.measureProperties = measureProperties;
		}
	}

	function parseStreamPropertyExtensions(entityType, csdl) {
		let streamProperties;
		if (entityType.baseEntityType && entityType.baseEntityType.streamProperties) {
			streamProperties = entityType.baseEntityType.streamProperties;
		}

		const properties =
			csdl.property && csdl.property.filter((item) => item.type === 'Edm.Stream');
		if (properties) {
			streamProperties = streamProperties ? streamProperties.slice(0) : [];
			for (const property of properties) {
				streamProperties.push({
					name: property.name,
					defaultContentType: property.annotation.find((d) => d.term === 'Glow.DefaultContentType').string,
				});
			}
		}

		if (streamProperties) {
			entityType.streamProperties = streamProperties;
		}
	}

	function parseTableCodeExtension(entityType, csdl) {
		if (entityType.baseEntityType) {
			entityType.tableCode = entityType.baseEntityType.tableCode;
		} else {
			const tableCode = csdl?.annotation?.find((d) => d.term === 'Glow.TableCode');
			entityType.tableCode = tableCode ? tableCode.string : null;
		}

		if (!entityType.tableCode) {
			const keyName = entityType.keyProperties[0].name;
			const _Index = keyName.indexOf('_');
			entityType.tableCode =
				_Index > 0 && _Index < keyName.length ? keyName.substr(0, _Index) : null;
		}

		if (entityType.tableCode) {
			entityType.isActivePropertyName = null;
			entityType.isCancellable = false;

			let property = entityType.getProperty(entityType.tableCode + '_IsActive');
			if (property) {
				entityType.isActivePropertyName = property.name;
			} else {
				property = entityType.getProperty(entityType.tableCode + '_IsCancelled');
				if (property) {
					entityType.isActivePropertyName = property.name;
					entityType.isCancellable = true;
				}
			}
		}
	}

	function parseInfoTypeExtension(entityType, csdl) {
		const infoTypeFor = csdl?.annotation?.find((d) => d.term === 'Glow.InfoTypeFor');
		if (infoTypeFor) {
			entityType.infoTypeFor = infoTypeFor.string;
		}

		const infoType = csdl?.annotation?.find((d) => d.term === 'Glow.InfoType');
		if (infoType) {
			entityType.infoType = infoType.string;
		}
	}

	breeze.MetadataStore = MetadataStore;
})();

(function extendPredicate() {
	breeze.Predicate.booleanPredicate = (bool) => {
		return new BooleanPredicate(bool);
	};

	breeze.Predicate.typeOfPredicate = (property, type) => {
		return new TypeOfPredicate(property, type);
	};

	breeze.Predicate.isNullPredicate = (property, isNull) => {
		return new IsNullPredicate(property, isNull);
	};

	OData4PredicateVisitor.prototype.binaryPredicate = function (context) {
		let expr1Val = this.expr1.visit(context);
		let expr2Val = this.expr2.visit(context);
		const prefix = context.prefix;
		if (prefix) {
			if (this.expr1.visitorMethodName !== 'fnExpr') {
				expr1Val = prefix + '/' + expr1Val;
			}
			if (this.expr2.propertyPath && this.expr2.visitorMethodName !== 'fnExpr') {
				expr2Val = prefix + '/' + expr2Val;
			}
		}

		const op = this.op.key;
		if (op === 'in') {
			const result = expr2Val
				.map((v) => {
					return '('.concat(expr1Val, ' eq ').concat(v, ')');
				})
				.join(' or ');
			return result;
		} else if (this.op.isFunction) {
			return ''.concat(op, '(').concat(expr1Val, ',').concat(expr2Val, ')');
		} else {
			return ''.concat(expr1Val, ' ').concat(op, ' ').concat(expr2Val);
		}
	};

	OData4PredicateVisitor.prototype.fnExpr = function (context) {
		const prefix = context.prefix;
		const exprVals = this.exprs.map((expr) => {
			let exprVal = expr.visit(context);
			if (prefix) {
				exprVal = prefix + '/' + exprVal;
			}
			return exprVal;
		});
		return ''.concat(this.fnName, '(').concat(exprVals, ')');
	};
})();

(function utils() {
	breeze.utils = {};

	/**
	 * @deprecated Use EntityRuleExtensions.enableProposedValuesAsync instead
	 * @example
	 * using(await enableProposedValuesAsync(entity), () => {
	 * // your code here
	 * });
	*/
	breeze.utils.runWithProposedValuesAsync = async (entity, action, validate) => {
		const result = await using(await enableProposedValuesAsync(entity), () =>
			validate ? action() : using(suspendValidation(entity), action)
		);
		return result;
	};

	/** @deprecated Use EntityCollectionExtensions.getFirstOrDefaultChildAsync instead  */
	breeze.utils.getFirstOrDefaultChildAsync = (entity, collectionOrPropertyName, values) => {
		return getFirstOrDefaultChildAsync(
			entity,
			propertyNameForCollection(collectionOrPropertyName),
			values
		);
	};

	/** @deprecated Use EntityCollectionExtensions.getOrCreateFirstChildAsync instead  */
	breeze.utils.getOrCreateFirstChildAsync = (entity, collectionOrPropertyName, values) => {
		return getOrCreateFirstChildAsync(
			entity,
			propertyNameForCollection(collectionOrPropertyName),
			values
		);
	};

	/** @deprecated Use EntityCollectionExtensions.getSingleOrDefaultChildAsync instead  */
	breeze.utils.getSingleOrDefaultChildAsync = (entity, collectionOrPropertyName, values) => {
		return getSingleOrDefaultChildAsync(
			entity,
			propertyNameForCollection(collectionOrPropertyName),
			values
		);
	};

	/** @deprecated Use EntityCollectionExtensions.getOrCreateSingleChildAsync instead  */
	breeze.utils.getOrCreateSingleChildAsync = (entity, collectionOrPropertyName, values) => {
		return getOrCreateSingleChildAsync(
			entity,
			propertyNameForCollection(collectionOrPropertyName),
			values
		);
	};

	/** @deprecated Use EntityCreationExtensions.getOrCreateRelatedObjectAsync instead  */
	breeze.utils.getOrCreateRelatedObjectAsync = (entity, propertyName) => {
		return getOrCreateRelatedObjectAsync(entity, propertyName);
	};

	breeze.utils.shallowCopyAsync = (entity, excludedPropertyNames, targetEntityType) => {
		let suspension;
		const entityType = targetEntityType || entity.entityType;
		return entity.entityAspect.entityManager
			.createEntityWithKeyAsync(entityType, { applyDefaultValues: false })
			.tap((result) => {
				suspension = validationEngine.suspendValidation([result]);
				return copyPropertiesAsync(entityType, excludedPropertyNames, entity, result);
			})
			.finally(() => {
				suspension && suspension.dispose();
			});
	};

	/** @deprecated Use EntityCopyExtensions.shallowCopyExistingAsync instead  */
	breeze.utils.shallowCopyExistingAsync = (entity, targetEntity, excludedPropertyNames) => {
		return shallowCopyExistingAsync(
			entity,
			targetEntity,
			excludedPropertyNames
		);
	};

	function propertyNameForCollection(collectionOrPropertyName) {
		return typeof collectionOrPropertyName === 'string'
			? collectionOrPropertyName
			: collectionOrPropertyName.navigationProperty.name;
	}

	function copyPropertiesAsync(entityType, excludedPropertyNames, entity, targetEntity) {
		const tableCode = entityType.tableCode;
		const sourceDataProperties = entityType.dataProperties;
		excludedPropertyNames = excludedPropertyNames || [];
		return Promise.all(
			sourceDataProperties.map((property) => {
				const isInExcludedList = excludedPropertyNames.includes(property.name);
				if (
					!property.isPartOfKey &&
					!isInExcludedList &&
					!systemAuditPropertiesHelper.isSystemAuditProperty(property.name, tableCode)
				) {
					return targetEntity.entityAspect.setValueAsync(
						property.name,
						entity.getProperty(property.name)
					);
				}
			})
		);
	}
})();

function getPropertyDescriptor(get) {
	return { configurable: true, enumerable: true, get };
}

function runBreezePropertyValidation(property, newValue) {
	if (property.isNavigationProperty) {
		if (newValue && newValue.entityAspect && newValue.entityAspect.entityState.isDetached()) {
			throw new Error(
				`Cannot set property ${property.parentType.interfaceName}.${property.name} to a detached entity of type ${newValue.entityType.interfaceName}.`
			);
		}
	} else {
		const validationContext = { property, propertyName: property.name };
		property.getAllValidators().forEach((validator) => {
			const error = validator.validate(newValue, validationContext);
			if (error) {
				const message = `Cannot set property ${property.parentType.interfaceName}.${
					property.name
				} to ${valueToString(newValue)}. ${error.errorMessage}.`;
				throw new Error(message);
			}
		});
	}
}

function valueToString(value) {
	if (value === null) {
		return 'null';
	} else {
		return "'" + value + "'";
	}
}

async function onPropertyChangedAsync(propertyChangedArgs) {
	if (propertyChangedArgs.propertyName !== null) {
		await onPropertyChangedCoreAsync(propertyChangedArgs);
	} else {
		const propertyChangedArgsList = propertyChangedArgs.entity.entityAspect.consumePropertyChangedArgsList();
		await Promise.all(propertyChangedArgsList.map(onPropertyChangedCoreAsync));
	}
}

async function onPropertyChangedCoreAsync({
	property,
	propertyName,
	entity,
	newValue,
	oldValue,
	suspendProposedValueAndValidation,
}) {
	if (!property || !property.isDataProperty || entity.entityAspect._propertyChangesSuspended) {
		return;
	}

	const { entityState } = entity.entityAspect;
	if (entityState.isDeleted() || entityState.isDetached()) {
		return;
	}

	entity.entityAspect.hasAnyPropertyChanged(true); // Note that this doesn't revert to false if you change the value back.

	const changes = [{ entity, propertyName }];
	const relatedNavProperty = property.relatedNavigationProperty;
	if (relatedNavProperty) {
		changes.push({ entity, propertyName: relatedNavProperty.name });

		const { inverse } = relatedNavProperty;
		if (inverse && !inverse.isScalar) {
			const { entityManager } = entity.entityAspect;
			[oldValue, newValue].forEach((value) => {
				if (!isEmptyGuid(value)) {
					const parent = entityManager.getEntityByKey(
						relatedNavProperty.entityType,
						value
					);
					if (parent) {
						changes.push({ entity: parent, propertyName: inverse.name });
					}
				}
			});
		}
	}

	const promise = suspendProposedValueAndValidation
		? tryNotifyChangesExceptProposedValueAsync(changes)
		: tryNotifyAndValidateChangesAsync(changes);
	await promise;
}

function tryNotifyChangesExceptProposedValueAsync(changes) {
	return Promise.all(
		changes.map((change) => tryNotifyDependentsAsync(change.entity, change.propertyName, true))
	);
}

function tryNotifyAndValidateChangesAsync(changes) {
	const notifications = [];
	const changesToValidate = [];
	changes.forEach((change) => {
		notifications.push(tryNotifyDependentsAsync(change.entity, change.propertyName, false));
		if (!validationEngine.isSuspended(change.entity)) {
			changesToValidate.push(change);
		}
	});

	return Promise.all(notifications).then(() => {
		if (changesToValidate.length) {
			return Promise.all(
				changesToValidate.map((c) =>
					validatePropertyAndDependentsAsync(c.entity, c.propertyName)
				)
			);
		}
	});
}

function tryNotifyDependentsAsync(entity, propertyName, isLoadedOnly) {
	return Promise.try(() => {
		const entityState = entity.entityAspect.entityState;
		if (!entityState.isDeleted() && !entityState.isDetached()) {
			const dependencyGraph = entity.entityAspect.entityManager.dependencyGraph;
			return dependencyGraph.notifyDependentsAsync(
				new PropertyVertex(entity, propertyName),
				isLoadedOnly
			);
		}
	});
}

async function validatePropertyAndDependentsAsync(entity, propertyName) {
	const entityState = entity.entityAspect.entityState;
	if (
		!entityState.isDeleted() &&
		!entityState.isDetached() &&
		!entity.entityAspect.entityManager.disableGlowValidationOnPropertyChange
	) {
		await validationEngine.validatePropertyAndDependentsAsync(entity, propertyName);
	}
}
/*! EndNoStringValidationRegion */

// Export breeze for BreezeExtender proxy module.
export default breeze;
